<template>
  <div>
    <v-menu
      offset-y
      bottom
      left
      origin="top right"
      max-height="400"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-badge bordered :content="unreadCount" color="red" overlap>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item
            v-for="notification in grouped_notifications"
            :key="notification.id"
            @click="openNotification(notification)"
          >
            <!-- Icon dynamically chosen based on notification type -->
            <v-list-item-avatar>
              <v-icon :color="getNotificationColor(notification.rule_type)">
                {{ getNotificationIcon(notification.rule_type) }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ notification.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                notification.count_message
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn @click="viewAllNotifications">View All</v-btn>
      </v-card>
    </v-menu>

    <NotificationDetails
      :showForm="NotifShowForm"
      :notifications="notifications"
      :title="title"
      :icon="icon"
      :color="color"
      :key="ndkey"
      @refresh="refresh"
      @close="NotifShowForm = false"
    />
  </div>
</template>

<script>
import GET_NOTIFICATIONS from "@/graphql/Notifications/GET_NOTIFICATIONS.gql";

export default {
  name: "DefaultNotifications",
  components: {
    NotificationDetails: () => import("./NotificationDetails.vue"),
  },
  data() {
    return {
      notifications: [],
      grouped_notifications: [],
      unreadCount: 0,
      NotifShowForm: false,
      title: "",
      icon: "",
      color: "",
      ndkey: 1540,
    };
  },
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          //  this.refresh();
        }
      },
    },
  },
  mounted() {
    //  this.refresh(); // Load notifications initially
    setInterval(() => {
      //   this.refresh(); // Refresh notifications every minute
    }, 60000);
  },
  methods: {
    // Get icon based on notification type
    getNotificationIcon(type) {
      switch (type) {
        case "info":
          return "mdi-information-outline";
        case "warning":
          return "mdi-alert-outline";
        case "critical":
          return "mdi-alert-circle-outline";
        case "alert":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline";
      }
    },
    // Get color based on notification type
    getNotificationColor(type) {
      switch (type) {
        case "info":
          return "blue";
        case "warning":
          return "orange";
        case "critical":
          return "red";
        case "alert":
          return "red";
        default:
          return "grey";
      }
    },
    viewAllNotifications() {
      this.$router.push({ name: "notifHistory" });
    },
    // Open notification and handle any required logic
    openNotification(item) {
      this.title = item.title;
      this.icon = item.icon;
      this.color = item.color;
      this.NotifShowForm = true;
      this.ndkey++;
    },

    // Mark individual notification as read
    group_notifs() {
      // Step 1: Group by event_rule_id and count occurrences
      const groupedNotifications = this.notifications
        .filter((n) => !n.is_read)
        .reduce((acc, notification) => {
          const typeId = notification.event_rule_id;

          // If the group for this notification type doesn't exist, create it
          if (!acc[typeId]) {
            acc[typeId] = {
              event_rule_id: typeId,
              rule_type: notification.rule_type,
              title: notification.title,
              icon: this.getNotificationIcon(notification.rule_type),
              color: this.getNotificationColor(notification.rule_type),
              count_message: notification.count_message,
              notif_count: 0, // Start with 0 and increment
            };
          }

          // Increment the notif_count for this group
          acc[typeId].notif_count++;

          return acc;
        }, {});

      // Step 2: Replace {{count}} with the actual count
      Object.values(groupedNotifications).forEach((group) => {
        group.count_message = group.count_message.replace(
          "{{count}}",
          group.notif_count
        );
      });

      // Step 3: Convert groupedNotifications back to an array (if needed)
      this.grouped_notifications = Object.values(groupedNotifications);
      this.unreadCount = this.grouped_notifications.length;
    },
    // Refresh notifications list from the server
    async refresh() {
      const result = await this.$apollo.query({
        query: GET_NOTIFICATIONS,
        variables: { user_id: this.$store.state.me.id },
        fetchPolicy: "network-only", // Ensures fresh data
      });
      if (result) {
        this.notifications = result.data.userNotifications;
        this.group_notifs();
      }
    },
  },
};
</script>

<style scoped>
.notification-read {
  opacity: 0.6;
}
.mark-all {
  background-color: #f0f0f0;
  cursor: pointer;
}
</style>
